import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import AboutView from "../views/AboutView";

const About = ({ location }) => {
    return (
        <Layout location={location} title="Blog">
            <Seo title="youhavetosleep" />
            <AboutView />
        </Layout>
    );
};

export default About;
