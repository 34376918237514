import React from "react";
import styled from "styled-components";

const AboutView = () => {
    return (
        <Container>
            <br />
            <br />
            <Section>
                <Content>안녕하세요. 방문을 환영합니다 :)</Content>
                <Content>프론트엔드 엔지니어 진정수입니다.</Content>
            </Section>
            <br />
            <Section>
                <Title>About</Title>
                <Content>개인이나 팀이 가진 아이디어를 사용 가능한 도구로 만드는 일에 큰 매력을 느낍니다.</Content>
                <Content>
                    일정한 호흡으로 오랜 시간 나아가는{" "}
                    <Link href="https://youhavetosleep.notion.site/Total-1-735km-b4e1d7d8e4ba4b07a762689731a7fa35">
                        달리기
                    </Link>
                    를 좋아합니다.
                </Content>
                <Content>달리기를 하듯 개발을 합니다.</Content>
            </Section>
            <br />
            <Section>
                <Title>Career</Title>
                <Content>
                    <Link href="https://www.codestates.com/">CodeState</Link> 31기를 수료했습니다.
                </Content>
                <Content>
                    <Link href="https://about.enkor.kr/">enkorwithus</Link> 프론트엔드 엔지니어로 근무하고 있습니다.
                </Content>
            </Section>
            <Section>
                <br />
                <EtcContent>
                    Contact - <a href="mailto:qksekf4@gmail.com">qksekf4@gmail.com</a>
                </EtcContent>
            </Section>
        </Container>
    );
};

export default AboutView;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Section = styled.section``;

const Title = styled.h3``;

const Content = styled.p`
    font-size: 17px;
    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
`;

const Link = styled.a`
    //TODO : styled component로 밑줄 표시 스타일 들어가지 않음
    box-shadow: inset 0 -5px 0 #ffe042;
    transition: 0.2s;
    transition-duration: 0.3s, 0.3s;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
    &:hover {
        box-shadow: inset 0 -20px 0 #f5ae2b;
        color: rgb(255, 255, 255);
    }
`;

const EtcContent = styled.p`
    font-size: 14px;
    @media screen and (max-width: 800px) {
        font-size: 12px;
    }
`;
